.mainTitle {
  font-family: 'Roboto', sans-serif; /* Use a specific font */
  font-size: 5.5em; /* Larger font size */
  color: white; /* Green color */
  text-align: center; /* Center-align the heading */
  text-transform: uppercase; /* Convert text to uppercase */
  margin-bottom: 20px; /* Space below the heading */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow for emphasis */
  font-weight: 700; /* Bold font weight */
  font-style: italic; /* Italic font style */
  letter-spacing: 2px; /* Increased letter spacing */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
